import React from 'react';
import PropTypes from 'prop-types';
import StoreButtons from '../common/storeButtons';

const HeroVideo = ({ video, heading, text, className = '', ...rest }) => (
  <section
    className={`space-around min-h-viewport relative flex ${className}`}
    {...rest}
  >
    {/* Background Video */}
    <div className="absolute inset-0">
      <video
        className="object-cover w-full h-full"
        muted
        autoPlay
        loop
        playsInline
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>

    {/* Backdrop */}
    <div className="absolute inset-0 bg-black opacity-40" />

    <div className="container relative m-auto max-w-prose section-colored">
      {/* Content */}
      <h1 className="text-main-title mb-4 md:mb-6">{heading}</h1>
      <p className="text-short-lead">{text}</p>

      {/* Store Buttons */}
      <StoreButtons campaign="home-header" className="mt-12 items-center" />
    </div>
  </section>
);

HeroVideo.propTypes = {
  video: PropTypes.string,
  heading: PropTypes.node,
  text: PropTypes.node,
  className: PropTypes.string,
};

export default HeroVideo;
