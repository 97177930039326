import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';

const Parallax = ({ children, trigger, depth = 1 }) => {
  const child = React.Children.only(children);
  const element = useRef();

  useEffect(() => {
    if (element.current) {
      const elFromTop = element.current.offsetTop;
      const elHeight = element.current.offsetHeight;

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: trigger.current,
          start: `+${elFromTop + elHeight / depth} bottom`,
          end: `+${elFromTop - elHeight / depth} top`,
          scrub: true,
        },
      });

      tl.fromTo(
        element.current,
        {
          y: elHeight / depth,
        },
        {
          y: -elHeight / depth,
        }
      );

      return () => {
        tl.scrollTrigger.kill();
        tl.kill();
      };
    }
  }, [element.current]);

  return React.cloneElement(child, {
    ref: element,
  });
};

Parallax.propTypes = {
  children: PropTypes.node.isRequired,
  trigger: PropTypes.object.isRequired,
  depth: PropTypes.number,
};

export default Parallax;
