import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import Layout from '../layout/layout';
import SEO from '../meta/seo';
import HeroVideo from './heroVideo';

import homeVideo from '../../assets/video/placeholder.mp4';
import ArrowRight from '../../assets/arrow-right.svg';
import Parallax from '../animations/parallax';

const Home = () => {
  const {
    parallaxSphere,
    madeForProfessionals,
    accessibleByEveryone,
    testimonialAvatar,
    starsRating,
    tellYourStory,
    happySplicers,
  } = useStaticQuery(graphql`
    {
      parallaxSphere: file(relativePath: { eq: "sphere.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      madeForProfessionals: file(
        relativePath: { eq: "made-for-professionals.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 592, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      accessibleByEveryone: file(
        relativePath: { eq: "accessible-by-everyone.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 592, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      testimonialAvatar: file(relativePath: { eq: "testimonial.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 64, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      starsRating: file(relativePath: { eq: "stars-rating.png" }) {
        childImageSharp {
          fluid(maxWidth: 160, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tellYourStory: file(relativePath: { eq: "tell-your-story.png" }) {
        childImageSharp {
          fluid(maxWidth: 568, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      happySplicers: file(relativePath: { eq: "happy-splicers.png" }) {
        childImageSharp {
          fluid(maxWidth: 1184, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const firstSection = useRef();
  const secondSection = useRef();

  return (
    <Layout campaign="home">
      <SEO
        title="The Best Video Editing App"
        description="Create videos that blow minds in a snap. Splice app makes it easy to create high-quality videos on your phone. Edit like a pro on the go."
      />

      <HeroVideo
        video={homeVideo}
        className="text-center"
        heading={
          <>
            <span className="stroked">Create videos</span>
            <br />
            that blow minds.
          </>
        }
        text={
          <>
            The most powerful mobile video editor around.{' '}
            <br className="hidden md:block" />
            Download Splice and start creating stunning videos within minutes.
          </>
        }
      />

      <section className="container space-around xl:py-32">
        <div className="text-and-image text-and-image--reverse text-and-image--tall">
          <div className="relative text-and-image__image" ref={firstSection}>
            <GatsbyImage
              className="z-10"
              fluid={madeForProfessionals.childImageSharp.fluid}
              alt="Made for professionals"
            />
            <Parallax trigger={firstSection} depth={5}>
              <div className="absolute z-0 w-40 h-40 md:w-80 md:h-80 -top-8 -right-4 md:-top-24 md:-right-16">
                <GatsbyImage
                  fluid={parallaxSphere.childImageSharp.fluid}
                  alt="Accent sphere"
                />
              </div>
            </Parallax>
            <Parallax trigger={firstSection} depth={-3}>
              <div className="absolute z-20 w-16 h-16 md:w-24 md:h-24 top-1/3 left-12 md:left-24">
                <GatsbyImage
                  fluid={parallaxSphere.childImageSharp.fluid}
                  alt="Accent sphere"
                />
              </div>
            </Parallax>
            <Parallax trigger={firstSection} depth={4}>
              <div className="absolute z-0 w-12 h-12 md:w-20 md:h-20 bottom-2 right-40 md:right-72 blur-light">
                <GatsbyImage
                  fluid={parallaxSphere.childImageSharp.fluid}
                  alt="Accent sphere"
                />
              </div>
            </Parallax>
          </div>
          <div className="text-and-image__text">
            <h2 className="text-title mb-4 md:mb-6">
              The choice of
              <br />
              <span className="text-gradient bg-splice-gradient-horizontal">
                Professionals.
              </span>
            </h2>

            <div className="lg:mr-14 mb-10">
              <p>
                All the power of a desktop video editor—in the palm of your
                hand. When professional content creators and influencers need
                their videos to stand out on social media, they rely on Splice.
              </p>
              <p>
                Create and share videos wherever you want, whenever you want, in
                just a few taps.
              </p>
            </div>

            <div className="bg-gray bg-testimonial-card rounded-4xl py-8 px-6 mb-10 shadow-card-dark lg:max-w-small-card">
              <p className="M20I+LH text-white mb-6">
                “Whether I&apos;m outside enjoying the California sunshine or
                inside hanging out; editing with Splice has made it easy to
                create my art time-lapses during any point of my day.”
              </p>
              <a
                href="https://www.instagram.com/boelterdesignco/"
                target="_blank"
                rel="noreferrer"
                className="flex items-center"
              >
                <div className="w-16 h-16 rounded-full overflow-hidden">
                  <GatsbyImage
                    fluid={testimonialAvatar.childImageSharp.fluid}
                    alt="Made for professionals"
                  />
                </div>
                <div className="ml-4">
                  <h4 className="B18 md:B24 m-0 text-accent-main text-shadow-glow transition-colors duration-300 hover:text-white">
                    @boelterdesignco
                  </h4>
                  <p className="M16 md:M18 m-0 text-white-70">
                    Content Creator
                  </p>
                </div>
              </a>
            </div>

            <div className="text-center lg:text-left">
              <Link to="/explore/" className="btn btn--outline">
                <div>Explore the features</div>
                <ArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="relative container space-around xl:py-32">
        <div className="text-and-image text-and-image--tall">
          <div className="relative text-and-image__image" ref={secondSection}>
            <GatsbyImage
              className="z-10"
              fluid={accessibleByEveryone.childImageSharp.fluid}
              alt="Accessible by everyone"
            />
            <Parallax trigger={secondSection} depth={4}>
              <div className="absolute z-20 w-40 h-40 md:w-80 md:h-80 -top-16 -left-4 md:-top-36 md:-left-12 blur-medium">
                <GatsbyImage
                  fluid={parallaxSphere.childImageSharp.fluid}
                  alt="Accent sphere"
                />
              </div>
            </Parallax>
            <Parallax trigger={secondSection} depth={-5}>
              <div className="absolute z-0 w-20 h-20 md:w-40 md:h-40 bottom-0 -right-4 md:-right-12">
                <GatsbyImage
                  fluid={parallaxSphere.childImageSharp.fluid}
                  alt="Accent sphere"
                />
              </div>
            </Parallax>
          </div>
          <div className="text-and-image__text">
            <h2 className="text-title mb-2 md:mb-4 xl:mb-6">
              Accessible by <br />
              <span className="text-gradient bg-splice-gradient-horizontal">
                Everyone.
              </span>
            </h2>

            <div className="flex flex-col md:flex-row mb-4 md:mb-6 md:items-center">
              <div className="w-full max-w-stars-fixed transform -translate-x-3">
                <GatsbyImage
                  fluid={starsRating.childImageSharp.fluid}
                  alt="5 stars rating"
                />
              </div>

              <h4 className="B18 md:B20 xl:B24 text-white m-0">
                4.7 Rating on the App Store
              </h4>
            </div>

            <p className="mb-10">
              Splice’s remarkably intuitive look and feel makes video editing
              accessible to everyone. Powerful editing tools aren’t just for
              pros. Amateurs looking to shoot, edit, and share can create
              something spectacular in next to no time.
            </p>

            <div className="text-center lg:text-left">
              <Link to="/explore/" className="btn btn--outline">
                {/* TODO: To be reverted back to tutorials */}
                <div>Explore the features</div>
                <ArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="container space-around">
        <div className="text-center mb-12 lg:mb-20">
          <h2 className="text-title mb-4 md:mb-6 max-w-content mx-auto">
            Discover what people are creating with Splice.
          </h2>

          <p className="text-long-lead max-w-content mx-auto">
            Our wonderfully talented creators are here to show you what’s
            possible with Splice. Check out some of our community best edits on
            our social media channels. Don’t forget to share your own using{' '}
            <a
              href="https://www.instagram.com/explore/tags/spliceapp/"
              target="_blank"
              rel="noreferrer"
            >
              #spliceapp.
            </a>
          </p>
        </div>

        {/* Social wall replacement */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-x-12">
          {/* TikTok card */}
          <a
            href="https://www.tiktok.com/@spliceapp?lang=en"
            target="_blank"
            rel="nofollow noreferrer"
            className="card card-pink"
          >
            <div className="mb-4 lg:mb-0">
              <h3 className="B28 md:B32 lg:B40 xl:B48 mb-6">
                Take your TikToks to another level.
              </h3>
              <p className="text-long-lead contrast-low">
                Impress your followers with mind-blowing edits in just a few
                taps.
              </p>
            </div>
            <div className="flex space-x-3 items-center">
              <p className="SB18 md:SB24 contrast-high m-0">Discover how</p>
              <ArrowRight />
            </div>
          </a>

          {/* Instagram card */}
          <a
            href="https://www.instagram.com/spliceapp/?hl=en"
            target="_blank"
            rel="nofollow noreferrer"
            className="card card-dark p-0 overflow-hidden"
          >
            <h3 className="B28 md:B32 lg:B40 xl:B48 p-8 md:p-12 pb-6">
              Tell your story.
            </h3>
            <GatsbyImage
              fluid={tellYourStory.childImageSharp.fluid}
              alt="Tell your story"
            />
          </a>

          {/* Reviews card */}
          <a
            href="https://apps.apple.com/us/app/splice-video-editor-maker/id409838725"
            target="_blank"
            rel="nofollow noreferrer"
            className="card card-dark col-span-full relative overflow-hidden hidden lg:flex"
          >
            <div className="mb-4 lg:mb-0">
              <h3 className="B28 md:B32 lg:B40 xl:B48 mb-6">Happy Splicers.</h3>
              <p className="text-long-lead contrast-low max-w-full-column">
                Our users love how simple it becomes to edit <br /> their videos
                with Splice. Don&apos;t believe it? <br /> Try it yourself!
              </p>
            </div>
            <div className="absolute inset-0 max-w-none h-full">
              <GatsbyImage
                fluid={happySplicers.childImageSharp.fluid}
                alt="Wall of reviews"
              />
            </div>
          </a>

          {/* TO DO: replace with the latest article or highlighted one, tbd in v1.1 */}
          <Link
            to="/blog/how-to-start-vlogging-on-instagram/"
            className="card card-outline"
          >
            <h3 className="B28 md:B32 lg:B40 xl:B48 mb-8 md:mb-0">
              How to start vlogging on Instagram
            </h3>
            <div className="flex space-x-3 items-center">
              <p className="SB18 md:SB24 contrast-high m-0">Read the article</p>
              <ArrowRight />
            </div>
          </Link>

          <a
            href="https://www.youtube.com/channel/UCe-ypppe3kUjkMGo959uexw"
            target="_blank"
            rel="nofollow noreferrer"
            className="card card-blue"
          >
            <div className="mb-4 lg:mb-0">
              <h3 className="B28 md:B32 lg:B40 xl:B48 mb-6">
                Get started with Splice!
              </h3>
              <p className="text-long-lead contrast-low">
                Learn how to edit videos like the pros, thanks to our exclusive
                free tutorials and How To lessons.
              </p>
            </div>
            <div className="flex space-x-3 items-center">
              <p className="SB18 md:SB24 contrast-high m-0">Splice tutorials</p>
              <ArrowRight />
            </div>
          </a>
        </div>
      </section>

      {/* <!-- Start of spliceapp Zendesk Widget script --> */}
      <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=a75f26d1-dd53-431e-8e61-e3ae89b7bad6"> </script>
      {/* <!-- End of spliceapp Zendesk Widget script --> */}
    </Layout>
  );
};

export default Home;
